.Review {
  height: auto;
  background-image: url("../../assets/images/Compressed Review Section BG Image (1) (2) 1.png");
  background-repeat: no-repeat;
  background-size: cover;  
  
  .Review_wrapper {
    padding: 4rem 0rem;
    margin: 0px 120px;
  
    > h2 {
      color: white;
      font-size: 2.5rem;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 3rem;
    }
  }
}


.review_holder {
  display: flex;
  width: 45vw;
  align-items: center;

  .R_Left_Button {
    border-style: none;
    height: 24px;
    outline: none;
    color: white;
    background-color: rgba(0, 0, 0, 0);
  }

  .review_card {
    width: 100%;
    flex-direction: row;
    overflow: hidden;
    scroll-snap-type: x mandatory;

    .R_container {
      display: flex;
    }
  }

  .R_Right_Button {
    border-style: none;
    height: 24px;
    outline: none;
    color: white;
    background-color: rgba(0, 0, 0, 0);
  }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {
  .Review {
    padding: 1rem 3rem;

    .Review_wrapper {
      margin: 0;
  
      > h2 {
        font-size: 2rem;
      }
    }
  }
  .review_holder {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .Review {
    padding: 1rem;
    margin-bottom: 1rem;
    
    .Review_wrapper {
      margin: 0;
  
      > h2 {
        color: white;
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
    
  }


  .review_holder {
    width: 100%;
  }

  .R_container {
    width: auto;
  }
}
