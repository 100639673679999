.A_Container {
  height: auto;
  background-color: #f7f7f7;
}
.A_Wrapper {
  padding: 4rem 0rem;
  display: flex;
  gap: 90px;
  justify-content: space-evenly;
  margin: 0px 120px;
  margin-right: 0px;
}
.A_Text_Wrap {
  flex: 1;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
}
.A_Image_Wrap {
  flex: 1;
  padding: 4rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../assets/images/Electric Circuit BG Design.png");
  background-position: right;
  margin-right: 3rem;
}

.A_Text_Header {
  font-weight: bold;
  font-size: 40px;
  margin: 0;
  line-height: 45px;
}
.A_Btn {
  border: 2px solid #00000063;
  background-color: transparent;
  padding: 0.5rem 2rem;
  color: black;
  font-weight: 500;
  font-size: 16px;
  width: fit-content;
  text-decoration: none;
}
.A_Btn:hover {
  color: white;
  transition: all 0.3s linear;
  background-color: #a5cf39;
}
.A_Image {
  width: 70%;
  padding-left: 4rem;
  padding-bottom: 2rem;
}

.A_Text_Title {
  color: #000015;
  line-height: 23.48px;
  font-size: 20px;
  font-weight: 300;
  text-align: start;
  letter-spacing: 2.5px;
}

.A_Green {
  color: #a4cf39;
}
.A_Text_Wrap p {
  text-align: justify;
  line-height: 22px;
}
@media screen and (min-width: 769px) and (max-width: 1199px) {
  .A_Wrapper {
    flex-direction: row;
    padding: 2rem 1rem;
    margin: 0;
  }
  .A_Image_Wrap {
    margin-right: 1rem;
    padding: 2rem;
  }
  .A_Image {
    padding-top: 2rem;
    padding-bottom: 0;
    padding-left: 0;
  }
  .A_Image_Wrap img {
    width: 100%;
  }
  .A_Text_Wrap p {
    line-height: 22px;
    font-size: 1rem;
  }
  .A_Text_Header {
    line-height: 30px;
    font-size: 1.5rem;
  }
  .A_Text_Title {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .A_Wrapper {
    flex-direction: column;
    padding: 2rem 1rem;
    margin: 0;
  }
  .A_Container {
    height: auto;
  }
  .A_Text_Wrap {
    margin-bottom: 3rem;
  }
  .A_Image {
    padding: 0;
  }
  .A_Image_Wrap {
    justify-content: center;
  }
  .A_Image_Wrap img {
    width: 100%;
    margin-top: 0;
  }
  .A_Text_Title {
    font-size: 14px;
  }
  .A_Text_Header {
    line-height: 1.5;
    font-size: 24px;
  }
  .A_Text_Wrap p {
    line-height: 22px;
    font-size: 1rem;
  }
}
