.Footer {
  background-color: black;
  width: 100%;
  height: 15%;
  padding: 0.5rem 0;
  display: flex;
  gap: 1rem;
  flex-direction: column-reverse;
  justify-content: space-between;
}
.F_text {
  font-weight: 300;
  color: #ffffff8a;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  margin-left: 120px;
}
.F_text p {
  margin: 0.1rem;
}
.F_Team {
  font-weight: bold !important;
}
.F_Socials {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
}
.F_item {
  color: black;
  margin-right: 1rem;
  font-size: 24px;
  display: flex;
  padding: 0.5rem;
  text-decoration: none;
  background-color: #a5cf39;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.F_item svg {
  align-self: center;
}
.F_item:hover {
  color: #666;
}
@media screen and (min-width: 769px) and (max-width: 1199px) {
  .Footer {
    justify-content: space-around;
  }
  .F_text {
    text-align: left;
    margin: 0;
    padding: 1rem;
  }
  .F_Socials {
    margin: 0;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
  }
  .F_item {
    margin-right: 0;
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  .Footer {
    flex-direction: column-reverse;
    justify-content: center;
    align-content: center;
  }
  .F_text {
    text-align: left;
    margin: 0;
    padding: 1rem;
  }
  .F_Socials {
    margin: 0;
    justify-content: center;
    gap: 2rem;
    margin-top: 1rem;
  }
  .F_item {
    margin-right: 0;
    font-size: 30px;
  }
}
